import { CookiesProvider } from "react-cookie";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AppContextProvider } from "./AppContextProvider";
import { ControlUi } from "./ControlUi";
import { ExampleRoom } from "./ExampleRoom";
import { Login } from "./Login";
import { Room } from "./Room";
import Presentation from "./slides-corso-ai";

export function App() {
  return (
    <CookiesProvider>
      <div id="App">
        <AppContextProvider>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<ControlUi />} />
              <Route path="/login" element={<Login />} />
              <Route path="/room/*" element={<Room room={ExampleRoom} />} />
              <Route path="/presentazione" element={<Presentation />} />
            </Routes>
          </BrowserRouter>
        </AppContextProvider>
      </div>
    </CookiesProvider>
  );
}
