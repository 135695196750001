import React from 'react';

const Card = ({ children, className = '' }:{children: any, className: String}) => (
  <div className={`rounded-lg shadow-lg bg-white ${className}`}>
    {children}
  </div>
);

const Slide = ({ title, content, chapter, time }:{title: String, content: any, chapter: String, time: String}) => (
  <Card className="w-full h-screen p-8 mb-8 bg-gradient-to-br from-blue-50 to-white">
    <div className="h-full flex flex-col">
      {/* Header */}
      <div className="text-sm text-gray-500 mb-2">AI Canvas - Creatività e Analisi per il Terzo Settore</div>
      
      {/* Main Content */}
      <div className="flex-grow">
        <h2 className="text-4xl font-bold text-blue-800 mb-6">{title}</h2>
        <div className="text-xl text-gray-700 mb-4">{chapter}</div>
        <div className="space-y-4">
          {content.map((item:any, index: React.Key | null | undefined) => (
            <div key={index} className="flex items-start space-x-3">
              <span className="text-2xl" content={item.emoji} />
              <div className="flex-grow">
                <div className="font-semibold text-lg text-gray-800">{item.title}</div>
                {item.details && <div className="text-gray-600">{item.details}</div>}
              </div>
            </div>
          ))}
        </div>
      </div>
      
      {/* Footer */}
      <div className="text-sm text-gray-500 mt-8">
        <span className="font-semibold">{time}</span>
      </div>
    </div>
  </Card>
);

const Presentation = () => {
  const slides = [
    {
      title: "Introduzione",
      chapter: "Capitolo 0",
      time: "14:00-14:15",
      content: [
        { emoji: "🎯", title: "Benvenuto e Obiettivi", details: "Potenzialità delle AI nel terzo settore" },
        { emoji: "📊", title: "Survey Partecipanti", details: "Analisi esperienza pregressa" },
        { emoji: "🔄", title: "Demo Live", details: "Prima esperienza con Midjourney" }
      ]
    },
    {
      title: "Le AI Generative",
      chapter: "Capitolo 1",
      time: "14:15-14:35",
      content: [
        { emoji: "🤖", title: "AI vs AI Generativa", details: "Differenze e peculiarità" },
        { emoji: "🧠", title: "Training e Dataset", details: "Come apprendono le AI" },
        { emoji: "💡", title: "Prompt Engineering", details: "L'arte di comunicare con le AI" },
        { emoji: "⚖️", title: "Etica e Limiti", details: "Uso responsabile delle AI" }
      ]
    },
    {
      title: "Applicazioni Non Profit",
      chapter: "Capitolo 2",
      time: "14:35-15:15",
      content: [
        { emoji: "📧", title: "Comunicazione", details: "Newsletter, Social, Campagne" },
        { emoji: "💰", title: "Fundraising", details: "Crowdfunding, Analisi, Call to Action" },
        { emoji: "🎨", title: "Visual Content", details: "Brand, Social Media, Infografiche" },
        { emoji: "📈", title: "Risultati", details: "Metriche e KPI" }
      ]
    },
    {
      title: "Strumenti e Costi",
      chapter: "Capitolo 3",
      time: "15:15-15:30",
      content: [
        { emoji: "💬", title: "ChatGPT", details: "Free vs Premium" },
        { emoji: "🎨", title: "Midjourney", details: "Piani e Abbonamenti" },
        { emoji: "🤖", title: "Claude", details: "Web vs API" },
        { emoji: "🎵", title: "SUNO", details: "Basic vs Pro" }
      ]
    },
    {
      title: "Prompt Engineering",
      chapter: "Capitolo 4",
      time: "15:30-15:50",
      content: [
        { emoji: "📋", title: "Framework BASE", details: "Struttura e Metodologia" },
        { emoji: "✍️", title: "Esempi Pratici", details: "Grant, Social, Audio" },
        { emoji: "✅", title: "Best Practices", details: "Specificità, Iterazione, Miglioramento" },
        { emoji: "🎯", title: "Focus Risultati", details: "Obiettivi e Metriche" }
      ]
    },
    {
      title: "Conclusioni",
      chapter: "Capitolo 5",
      time: "15:50-16:00",
      content: [
        { emoji: "📌", title: "Key Takeaways", details: "Punti chiave da ricordare" },
        { emoji: "📚", title: "Risorse Utili", details: "Guide e Tutorial" },
        { emoji: "❓", title: "Q&A", details: "Domande e Risposte" },
        { emoji: "🔄", title: "Feedback", details: "Raccolta feedback partecipanti" }
      ]
    }
  ];

  return (
    <div className="space-y-8">
      {slides.map((slide, index) => (
        <Slide key={index} {...slide} />
      ))}
    </div>
  );
};

export default Presentation;
